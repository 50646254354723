/*--------------------------------------------------------------
2. header
--------------------------------------------------------------*/


/*-- 2.1 topbar --*/
.top-none{
	.topbar{
		display: none;
	}
}
.topbar {
	padding: 0 130px;
	position: relative;
	background: $dark-gray;
	z-index: 1;

	@media (max-width:1700px) {
		padding: 0 30px;
	}

	&::after {
		position: absolute;
		right: 0;
		top: 0;
		width: 60%;
		height: 100%;
		background: $theme-primary-color;
		content: '';
		z-index: 1;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%, 2% 51%);
		z-index: -1;

		@media (max-width:1770px) {
			width: 55%;
		}

		@media (max-width:1599px) {
			width: 50%;
		}

		@media (max-width:1449px) {
			width: 45%;
		}

		@media (max-width:991px) {
			display: none;
		}
	}
}

@media (max-width: 991px) {

	.topbar {
		text-align: center;
		padding: 0;
	}
}


.topbar {
	font-size: 16px;
	color: #e5e3e3;
	margin: 0;

}

.topbar ul {
	overflow: hidden;
	list-style: none;
	float: right;
	margin-bottom: 0;
}

@media (max-width: 991px) {

	.topbar ul {
		float: none;
		display: inline-block;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.topbar ul li {
	font-size: 16px;
	float: left;
	padding: 15px 10px;
	color: #e5e3e3;
	line-height: 1em;





}

.topbar .contact-info ul li.language {


	select {
		background: none;
		border: none;
		color: $white;
		margin-top: -5px;
		height: 20px;

		option {
			color: $dark-gray;
		}

		&:focus {
			outline: none;
		}
	}
}

.topbar .contact-info {
	li {
		position: relative;


		&:before {
			position: absolute;
			right: 0;
			top: 10px;
			width: 1px;
			height: 25px;
			background: $white;
			content: "";

			@media(max-width:767px) {
				display: none;
			}
		}

	}
}

.topbar .contact-info ul li a {
	color: $white;
}


.topbar .contact-info ul li a:hover {
	color: $theme-primary-color-s2;
}


.topbar ul li:first-child {
	padding-left: 0;
}


.topbar .contact-intro ul {
	float: left;
	margin-bottom: 0;

	@media (max-width: 991px) {
		float: none;
	}
}

@media (max-width: 1200px) {

	.topbar ul li {
		font-size: 12px;
		padding: 15px 10px;
	}
}

@media (max-width: 991px) {

	.topbar ul li {
		font-size: 13px;
	}

	.topbar {
		padding: 10px 0;
	}

}

@media (max-width: 767px) {

	.topbar ul li {
		float: left;
		border: none;
		padding: 8px 6px;
		font-size: 13px;
	}

}

@media (max-width: 590px) {

	.topbar ul li {
		float: none;
	}

	.topbar .contact-info ul li {
		float: left;
	}

	.topbar ul {
		flex-wrap: wrap;
	}

}



.topbar .theme-btn-s2 {
	padding: 15px 20px;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: -3px;
	color: #fff !important;
}

.topbar ul .fi {
	position: relative;
	top: 1px;
	display: inline-block;
	padding-right: 3px;
}

.topbar ul .fi:before {
	font-size: 15px;
	font-size: 1rem;
	color: #fff;
}





.navigation {
	border: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-o-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
	position: fixed;
  top: 0px;
	left: 0px;
  width: 100%;
	height: 80px;
	padding: 0 130px;
	display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
	transition: background 0.3s ease;
  will-change: background, height;

	&.scrolled {
		// background: linear-gradient(rgba(255,255,255, 0.5), transparent);
		background: #fff;
		box-shadow: 0px 15px 20px -15px rgba(62, 65, 159, 0.1);
	}

	.navbar-header {
		width: 100%;
		position: relative;
		.navbar-brand {
			position: absolute;
			left: 50%;
			-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
			top: 55px;
			display: flex;
			justify-content: center;
			align-items: center;
			@media (max-width:575px) {
				left: 55%;
			}


			.logo {
				width: 500px;
				height: auto;
				// opacity: 0.6;
				transition: opacity 0.8s cubic-bezier(0.5, 0, 0, 1), width 0.8s cubic-bezier(0.5, 0, 0, 1), transform 0.8s cubic-bezier(0.5, 0, 0, 1), padding-bottom 0.8s cubic-bezier(0.5, 0, 0, 1);
				will-change: width, transform, padding-bottom;
				// transform: translate3d(0px, 12px, 0px);

				@media (max-width:575px) {
					max-width: 390px;
					width: 390px;
				
				}

				@media (max-width:430px) {
					max-width: 350px;
					width: 350px;
				
				}

				@media (max-width:375px) {
					max-width: 340px;
					width: 340px;
				
				}

				@media (max-width:325px) {
					max-width: 280px;
					width: 280px;
				
				}
			}

			&.scrolled {
				justify-content: flex-start;
				top: -14px;
				left: 0;
				-webkit-transform: none;
  			transform: none;

				@media (max-width:375px) {
					top: -10px;
				}
			}

		}
		
	
		&.scrolled {
			.logo{
				width: 300px;

				@media (max-width:575px) {
					max-width: 260px;
					width: 260px;
				
				}

				@media (max-width:375px) {
					max-width: 230px;
					width: 230px;
				
				}

				@media (max-width:325px) {
					max-width: 200px;
					width: 200px;
				}
  
			}
		}
		
	}
}



.navigation>.container {
	position: relative;
}


#navbar {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
	width: 100%;
}

#navbar>ul li a:hover,
#navbar>ul li a:focus {
	text-decoration: none;
	color: $theme-primary-color;
}

@media screen and (min-width: 992px) {
	#navbar {
		/*** hover effect ***/
		justify-content: right;
	}

	#navbar li {
		position: relative;
	}

	#navbar>ul>li>a:before {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 4px;
		background: $theme-primary-color;
		content: "";
		opacity: 0;
		visibility: hidden;
		transition: all .3s;
		border-radius: 3px;
	}

	#navbar>ul>li>a:hover:before,
	#navbar>ul>li>a.active:before {
		opacity: 1;
		visibility: visible;
	}

	#navbar>ul>li>.sub-menu{
		
		display: flex;
    flex-direction: column;
	}

	#navbar>ul>li>.sub-menu>.menu-item-has-children>a {
		position: relative;
	}

	#navbar>ul>li>.sub-menu>.menu-item-has-children>a:before {
		font-family: "themify";
		content: "\e649";
		font-size: 11px;
		font-size: 0.73333rem;
		position: absolute;
		right: 15px;
		top: 58%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	#navbar>ul>li:hover>.sub-menu {
		top: 100%;
		visibility: visible;
		opacity: 1;
	}

	#navbar .sub-menu>li:hover>.sub-menu {
		left: 100%;
		visibility: visible;
		opacity: 1;
	}
}

.wpo-site-header .container-fluid {
	padding-right: 0;
}

@media (max-width: 991px) {

	.wpo-site-header .container {
		width: 100%;
		max-width: 100%;
	}

	.wpo-site-header .header-right {
		right: -10px;
	}

	.wpo-site-header .navbar-header button span {
		background-color: #fff;
		width: 20px;
		display: block;
		height: 2px;
		margin-bottom: 5px;
	}

	.wpo-site-header .navbar-header button span:last-child {
		margin: 0;
	}
}

#navbar {
	ul {
		list-style: none;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
}

@media (max-width: 991px) and (max-width: 991px) {
	#navbar {
		top: 0;
		visibility: visible;
		opacity: 1;
	}
}

@media (max-width: 991px) {
	#navbar>ul {
		z-index: 101;
	}

	#navbar ul a {
		color: #000;
	}

	#navbar ul a:hover,
	#navbar ul li.current a {
		color: $theme-primary-color;
	}

	#navbar .close-navbar .ti-close {
		position: relative;
		top: 1px;
	}

	.wpo-site-header .navbar-toggler .first-angle,
	.wpo-site-header .navbar-toggler .last-angle {
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}

	.wpo-site-header .x-close .middle-angle {
		opacity: 0;
	}

	.wpo-site-header .x-close .first-angle {
		position: absolute;
		-webkit-transform: rotate(-44deg);
		top: 16px;
		left: 10px;
	}

	.wpo-site-header .x-close .last-angle {
		-webkit-transform: rotate(44deg);
		position: absolute;
		top: 16px;
		right: 10px;
	}
}



@media (max-width: 767px) {


	#navbar .navbar-nav {
		margin: 0;
	}
}

@media (max-width: 991px) {
	.wpo-site-header .navbar-collapse.collapse {
		display: none;
	}

	.wpo-site-header .navbar-collapse.collapse.in {
		display: block;
	}

	.wpo-site-header .navbar-header .collapse,
	.wpo-site-header .navbar-toggle {
		display: block;
	}

	.wpo-site-header .navbar-header {
		float: none;
		position: relative;
		z-index: 99;
		text-align: center;
	}

	.wpo-site-header .navbar-right {
		float: none;
	}

	.wpo-site-header .navbar-nav {
		float: none;
	}

	.wpo-site-header .navbar-nav>li {
		float: none;
	}
}

.wpo-site-header .header-right {
	display: flex;
	justify-content: flex-end;
	z-index: 991;
	position: relative;
	align-items: center;
}

.wpo-site-header .header-right button {
	padding: 0;
	border: 0;
	outline: 0;
}

.wpo-site-header .header-right>div {
	float: left;
	position: relative;
	margin-right: 20px;

	@media (max-width:1599px) {
		margin-right: 0px;
	}
}

.wpo-site-header .header-right {
	.close-form {
		.theme-btn {


			@media(max-width:1400px) {
				padding: 10px 15px;
				font-size: 15px;
			}
		}
	}
}

.wpo-site-header {

	.navigation {
		border: 0;
		@include rounded-border(0);

		>.container {
			position: relative;
		}

		@include media-query(991px) {
			padding: 20px 0;
		}
	}

	#navbar {
		@include transition-time(0.5s);

		>ul li a:hover,
		>ul li a:focus {
			text-decoration: none;
			color: darken($theme-primary-color, 5%);
		}

		// style for widther screen
		@include widther(992px) {
			li {
				position: relative;
			}

			>ul>li>a {
				font-size: 18px;
				padding: 35px 20px;
				display: block;
				color: $dark-gray;
				font-weight: 500;
				position: relative;

				@media(max-width:1870px) {
					padding: 35px 18px;
				}
			}

			>ul .sub-menu {
				background-color: #fff;
				box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);
				width: 225px;
				position: absolute;
				padding: 20px 0;
				left: 0;
				top: 110%;
				z-index: 10;
				visibility: hidden;
				opacity: 0;
				@include transition-time(0.3s);
				
			}

			>ul>li .sub-menu a {
				font-size: 16px;
				display: block;
				padding: 10px 15px;
				color: $dark-gray;
				position: relative;
				overflow: hidden;
				font-weight: 700;

				&:after {
					position: absolute;
					left: 15px;
					bottom: 4px;
					width: 0px;
					height: 2px;
					content: "";
					background: $theme-primary-color;
					transition: all .3s;
					opacity: 0;
					visibility: hidden;
				}

				&:hover,
				&.active {
					&:after {
						width: 40px;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			>ul>li>.sub-menu .sub-menu {
				left: 110%;
				top: 0;
			}

			>ul>li>.sub-menu>.menu-item-has-children>a {
				position: relative;

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					font-size: calc-rem-value(11);
					position: absolute;
					right: 15px;
					top: 50%;
					@include translatingY();
				}
			}

			/*** hover effect ***/
			>ul>li:hover>.sub-menu {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}

			.sub-menu>li:hover>.sub-menu {
				left: 100%;
				visibility: visible;
				opacity: 1;
			}
		}

		@include media-query(991px) {

			>ul>li a {
				display: block;
				font-size: 15px;
				color: $white;
				font-weight: 500;

				&:hover,
				&.active {
					color: $white;
				}
			}

			>ul .sub-menu>li:last-child {
				border-bottom: 0;
			}

			>ul>li>.sub-menu a {
				padding: 8px 15px 8px 45px;
			}

			>ul>li>.sub-menu .sub-menu a {
				padding: 8px 15px 8px 65px;
			}

			>ul .menu-item-has-children>a {
				position: relative;
				color: #ddd;

				&:hover,
				&.active {
					color: #fff;
				}

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					font-size: calc-rem-value(11);
					position: absolute;
					right: 30px;
					top: 50%;
					@include translatingY();
					@include transition-time(0.3s);
				}

				&.rotate {
					&:before {
						transform: rotate(90deg);
						top: 29%;
					}
				}

				&.rotate {
					color: #fff;
				}
			}
		}
	}

	/* navigation open and close btn hide for width screen */
	@include widther(992px) {
		.navbar-header .open-btn {
			display: block;
		}

		#navbar .close-navbar {
			display: block;
		}
	}

	.menu-close {
		display: block;
		color:#fff;
		position: absolute;
    right: 20px;
    top: 20px;
	}

	.mobail-menu button {
		background-color: $theme-primary-color;
		width: 40px;
		height: 35px;
		border: 0;
		padding: 5px 10px;
		outline: 0;
		position: relative;
		z-index: 20;

		&:focus {
			outline: none;
			box-shadow: none;
		}


		span {
			background-color: $white;
			width: 20px;
			display: block;
			height: 2px;
			margin-bottom: 5px;

			&:last-child {
				margin: 0;
			}
		}
	}

	/* style for navigation less than 992px */
	@include media-query (991px) {
		.container {
			width: 100%;
		}

		

		#navbar {
			background: #243352;
			display: block !important;
			width: 300px;
			height: 100%;
			margin: 0;
			padding: 0;
			border: 0;
			position: fixed;
			left: -320px;
			top: 0px;
			z-index: 999999;
			opacity: 0;
			visibility: hidden;

			.navbar-nav {
				display: block;
			}

			>ul {
				position: relative;
				z-index: 101;
			}

			ul a {
				color: $black;
			}

			ul a:hover,
			ul li.current a {
				color: $theme-primary-color;
			}

			.navbar-nav {
				height: 100%;
				overflow: auto;
				padding-bottom: 60px;
			}

			.close-navbar {
				background-color: $white;
				width: 40px;
				height: 40px;
				color: $black;
				border: 0;
				outline: none;
				position: absolute;
				left: -41px;
				top: 90px;
				z-index: 20;

				.ti-close {
					position: relative;
					top: 1px;
				}
			}


		}

		.menu-close {
			display: block;
			z-index: 99;
			background: none;
			text-align: center;
			color: #fff;
			border: 0;
			text-align: center;
			margin-left: auto;
			margin-top: 30px;
			margin-right: 30px;
			margin-bottom: 30px;
			width: 40px;
			height: 40px;
			line-height: 40px;
			border: 1px solid $white;
		}

		/* class for show hide navigation */
		.slideInn {
			left: 0 !important;
			opacity: 1 !important;
			visibility: visible !important;
		}

		.navbar-toggler .first-angle,
		.navbar-toggler .last-angle {
			@include transition-time(0.3s);
		}

		.x-close .middle-angle {
			opacity: 0;
		}

		.x-close .first-angle {
			position: absolute;
			-webkit-transform: rotate(-44deg);
			top: 16px;
			left: 10px;
		}

		.x-close .last-angle {
			-webkit-transform: rotate(44deg);
			position: absolute;
			top: 16px;
			right: 10px;
		}
	}

	@include media-query(767px) {
	

		#navbar .navbar-nav {
			margin: 0;
		}
	}




	/*navbar collaps less then 992px*/
	@include media-query (991px) {
		.navbar-collapse.collapse {
			display: none;
		}

		.navbar-collapse.collapse.in {
			display: block;
		}

		.navbar-header .collapse,
		.navbar-toggle {
			display: block;
		}

		.navbar-header {
			float: none;
		}

		.navbar-right {
			float: none;
		}

		.navbar-nav {
			float: none;
		}

		.navbar-nav>li {
			float: none;
		}
	}


}

.mobail-menu {
	display: none;
}

@media (max-width: 450px) {

	#navbar {
		display: none;
	}

	.mobail-menu{
		display: block;
	}

	.wpo-site-header .navigation{
		padding: 20px
	}
	.wpo-site-header .header-right>div {
		margin-right: 10px;
	}

	.wpo-site-header .header-right {
		right: -30px;
	}
}

.wpo-site-header .header-right>div:last-child {
	margin: 0 0 0 10px;
	position: relative;

	@media (max-width: 767px) {
		margin: 3px 0 0 10px;
	}

}

.wpo-site-header .header-right .header-search-form-wrapper {
	position: relative;
}



.wpo-site-header .header-right .header-search-form-wrapper .search-toggle-btn .fi {
	font-size: 18px;
	color: $white;
}


.wpo-site-header .header-right .cart-toggle-btn {
	@media(max-width:450px) {
		display: none;
	}
}

.wpo-site-header .header-right .search-toggle-btn,
.wpo-site-header .header-right .cart-toggle-btn {
	background-color: rgba(7, 1, 67, .05);
	line-height: 0.66em;
	color: $white;
	width: 50px;
	height: 50px;
	border: 0;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	border-radius: 50%;

	@media(max-width:1200px) {
		width: 50px;
		height: 50px;
	}

	@media(max-width:991px) {
		width: 40px;
		height: 40px;
	}

	.fi {
		&:before {
			@media(max-width:991px) {
				font-size: 16px;
			}
		}
	}

	.ti-close {
		display: block;
		line-height: 20px;
		position: relative;
		top: -2px
	}

}


.wpo-site-header .header-right .header-search-content-toggle {
	opacity: 1;
	visibility: visible;
	right: 0;
	transform: scale(1);
}



@media (max-width: 991px) {
	.page-wrapper {
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}

	.body-overlay:before {
		content: "";
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.9);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}
}









@media(max-width: 1500px) {
	#navbar>ul>li>a {
		padding: 30px 15px;
	}
}

@media(max-width: 1400px) {
	#navbar>ul>li>a {
		padding: 30px 10px;
		font-size: 18px;
	}

}

@media(max-width: 1200px) {
	#navbar>ul>li>a {
		padding: 30px 10px;
		font-size: 16px;
	}

	.wpo-site-header .header-right .theme-btn {
		display: none;
	}
}

@media(max-width: 991px) {
	#navbar>ul>li>a {
		font-size: 15px;
		padding: 15px 20px;
	}
}

@media (max-width: 767px) {
	.header-right .theme-btn {
		padding: 20px 12px;
	}

	.wpo-site-header .header-right .close-form {
		display: none;
	}

}

.navigation.sticky-header {
	padding: 0 130px;

	@media (max-width: 1700px) {
		padding: 0 30px;
	}

	@media (max-width: 1500px) {
		padding: 0 12px;
	}

}

/* wpo-site-header-s2 */
.wpo-site-header-s2,
.wpo-site-header-s3 {
	position: unset;



	@media screen and (min-width: 992px) {

		#navbar>ul>li>a {
			color: $dark-gray;
		}
	}

	.header-right .cart-toggle-btn {
		color: $dark-gray;
	}

	.header-right .header-search-form-wrapper .search-toggle-btn .fi {
		color: $dark-gray;
	}

	.sticky-header {
		background: $white;
	}

	@media (max-width: 1599px) {
		.header-right>div {
			margin-right: 10px;
		}

	}

	.theme-btn {
		@media (max-width:1760px) {
			padding: 17px 38px;
		}
	}
}



/* wpo-site-header-s4 */

.wpo-site-header-s4 {
	border-bottom: 1px solid rgb(224, 224, 224, 0.2);

	&::after{
		position: absolute;
		left: 18%;
		top: 0;
		height: 100%;
		width: 1px;
		content: '';
		background: rgb(224, 224, 224, 0.2);
		@media (max-width:1699px) {
			left: 15%;
		}
		@media (max-width:1199px) {
			left: 18%;
		}
		@media (max-width:399px) {
			left: 22%;
		}
	}
}

.header-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.navlist {
	display: none;
	#navbar {
		-webkit-transition: all 0.5s;
		-moz-transition: all 0.5s;
		-o-transition: all 0.5s;
		-ms-transition: all 0.5s;
		transition: all 0.5s;

		ul {
			list-style: none;
			display: flex;
			flex-direction: row;

			a {
				color: #000;

			}

			&:hover, li.current a {
				color: $theme-primary-color;
			}
		}
		@include transition-time(0.5s);

		>ul li a:hover,
		>ul li a:focus {
			text-decoration: none;
			color: darken($theme-primary-color, 5%);
		}

		// style for widther screen
		@include widther(992px) {
			li {
				position: relative;
			}

			>ul>li>a {
				font-size: 18px;
				padding: 35px 20px;
				display: block;
				color: $white;
				font-weight: 500;
				position: relative;

				@media(max-width:1870px) {
					padding: 35px 18px;
				}
			}

			>ul .sub-menu {
				background-color: #fff;
				box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);
				width: 225px;
				position: absolute;
				padding: 20px 0;
				left: 0;
				top: 110%;
				z-index: 10;
				visibility: hidden;
				opacity: 0;
				@include transition-time(0.3s);
			}

			>ul>li .sub-menu a {
				font-size: 16px;
				display: block;
				padding: 10px 15px;
				color: $dark-gray;
				position: relative;
				overflow: hidden;
				font-weight: 700;

				&:after {
					position: absolute;
					left: 15px;
					bottom: 4px;
					width: 0px;
					height: 2px;
					content: "";
					background: $theme-primary-color;
					transition: all .3s;
					opacity: 0;
					visibility: hidden;
				}

				&:hover,
				&.active {
					&:after {
						width: 40px;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			>ul>li>.sub-menu .sub-menu {
				left: 110%;
				top: 0;
			}

			>ul>li>.sub-menu>.menu-item-has-children>a {
				position: relative;

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					font-size: calc-rem-value(11);
					position: absolute;
					right: 15px;
					top: 50%;
					@include translatingY();
				}
			}

			/*** hover effect ***/
			>ul>li:hover>.sub-menu {
				top: 100%;
				visibility: visible;
				opacity: 1;
			}

			.sub-menu>li:hover>.sub-menu {
				left: 100%;
				visibility: visible;
				opacity: 1;
			}
		}

		@include media-query(991px) {

			>ul>li a {
				display: block;
				font-size: 15px;
				color: $white;
				font-weight: 500;

				&:hover,
				&.active {
					color: $white;
				}
			}

			>ul .sub-menu>li:last-child {
				border-bottom: 0;
			}

			>ul>li>.sub-menu a {
				padding: 8px 15px 8px 45px;
			}

			>ul>li>.sub-menu .sub-menu a {
				padding: 8px 15px 8px 65px;
			}

			>ul .menu-item-has-children>a {
				position: relative;
				color: #ddd;

				&:hover,
				&.active {
					color: #000;
				}

				&:before {
					font-family: "themify";
					content: "\e649";
					font-size: 11px;
					font-size: calc-rem-value(11);
					position: absolute;
					right: 30px;
					top: 50%;
					@include translatingY();
					@include transition-time(0.3s);
				}

				&.rotate {
					&:before {
						transform: rotate(90deg);
						top: 29%;
					}
				}

				&.rotate {
					color: #fff;
				}
			}
		}
	}

	&.scrolled {
		display: inline-block;
	}
}
