/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

// fonts
$base-font-size: 15;
$base-font: "Noto Sans", sans-serif;
$heading-font: "Noto Sans", sans-serif;



// color
$dark-gray: #000B47;
$body-color: #5d5851;
$white: #fff;
$light: #F8F8F8;//
$black: #000;//
$small-black: #625F71;//
$cyan: #525252;//

$theme-primary-color: #CC3A29;//
$theme-primary-color-s2: #233FD6;//
$body-bg-color: #fff;
$section-bg-color: #fafbfe;
$text-color: #1A1729;//
$text-light-color: #545454;//
$heading-color: $dark-gray;
$border-color: #ebebeb;
$border-color-s2: #d8e0f1;