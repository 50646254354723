/*=====================================================
7. wpo-service-single
======================================================*/
.wpo-service-single-area{
    .wpo-service-single-title{
        h2{
            font-weight: 600;
						font-size: 32px;
        }
    }
    .wpo-service-single-wrap{
        .wpo-service-single-title{

            h3{
                font-weight: 400;
                font-size: 26px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px){
                    font-size: 25px;
                }
            }
        }
        .wpo-service-single-item{
            margin-bottom: 40px;
            .wpo-service-single-main-img{
                margin-bottom: 30px;

								img{
									width: 100%;
								}
            }

        }
        .wpo-service-single-item-centered{
            @extend .wpo-service-single-item;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .list-widget{
            max-width: 590px;

            ul{
                list-style: none;

                li{
                    padding: 10px 0;
                    padding-left: 35px;
                    position: relative;

                    &:before{
                       position: absolute;
                       left: 0;
                       top: 50%;
                       transform: translateY(-50%);
                       width: 25px;
                       height: 25px;
                       line-height: 25px;
                       border-radius: 50%;
                       background: transparentize($theme-primary-color, .9);
                       content: "\e64c";
                       font-family: "themify";
                       text-align: center;
                       color: $theme-primary-color;
                       font-size: 14px;
                    }
                }
            }
        }

        .wpo-p-details-img{
            margin-bottom: 10px;
        }

        .wpo-service-area{
            padding-bottom: 0;

            .wpo-service-item {
                padding: 20px;

                i{
                    &::before{
                        font-size: 50px;
                        color: $theme-primary-color;
                    }
                }

                @media(max-width:1200px){
                    padding: 20px 10px;
                  }

                h2{
                    font-weight: 700;
                    font-size: 22px;
                    margin-bottom: 5px;
                    text-transform: capitalize;

                    @media(max-width:1400px){
                      font-size: 19px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:1200px){
                      font-size: 17px;
                      margin-bottom: 10px;
                    }
                    @media(max-width:991px){
                      font-size: 22px;
                    }
                }
                p{
                    margin-bottom: 5px;
                    font-size: 16px;
                }
            }
        }

        .wpo-service-contact-area{
            padding: 30px;
            box-shadow: 0px 2px 8px 2px rgba(22, 14, 71, 0.1);
            border-radius: 20px;
            background: $white;

            .wpo-contact-title{
                margin-bottom: 30px;
            }

            .wpo-contact-form-area{
                .col{
                   margin-bottom: 30px;
                }
                .form-control{
                    height: 50px;
                    border:0 ;
                    border-bottom: 1px solid $border-color;
                    border-radius: 0;

                    &:focus{
                        outline: none;
                        box-shadow: none;
                    }
                }
                textarea.form-control{
                    height: 120px;
                }
                select.form-control{
                    background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
                }

                .submit-area{
                    button{
                    }
                }
            }
        }
    }
}

	/*** tag-share ***/
	.tag-share,.tag-share-s2 {
		// border-bottom: 1px solid $border-color;
		// margin: 75px 0 0;
		padding-bottom: 30px;
		color: $heading-color;

		@media(max-width:767px){
          margin-top: 40px;
		}

		ul {
			list-style: none;
			display: inline-block;
			overflow: hidden;

			li {
				float: left;

				@include media-query(767px) {
					margin: 2px;
				}
			}

			> li + li {
				margin-left: 10px;

				@include media-query(767px) {
					margin: 2px;
				}
			}
		}

		.tag {

			display: flex;
			align-items: center;

			> span {
				font-family: $heading-font;
				color: $heading-color;
				font-weight: 600;
				display: inline-block;
				padding-right: 15px;
				text-transform: uppercase;
			}

			ul {
				list-style: none;
				position: relative;
			}

			li {
				position: relative;
			}


			.tag-btn {
				font-size: 14px;
				display: inline-block;
				padding: 5px 18px;
				color: $dark-gray;
				background: #ecf4fb;
				border-radius: 5px;
				text-transform: uppercase;
                cursor: pointer;

				@include media-query(767px) {
					font-size: 13px;
					font-size: calc-rem-value(13);
				}

                &.active {
                    // background-color: rgb(255, 94, 20,0.7);
                    background-color: $theme-primary-color;
                    color: white;
                }

				&:hover {
                    background-color: rgb(255, 94, 20,0.7);
					color: $theme-primary-color;
				}
			}
		}


	}

    .hover-card {
        height: 200px;
        position: relative;
        overflow: hidden;

        @media (max-width:455px) {
            height: 300px;
        }

        .text-white {
            color: white !important;
        }

        .card-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            font-weight: bold;
            z-index: 10;
            transition: all 0.5s ease-in-out;
            color: $heading-color;
            line-height: 1.1;
            text-align: center;

        }
        .card-paragrap-nopad {
            position: absolute;
            z-index: 10;
            transition: all 0.5s ease-in-out;
            text-align: left;
            width: 80%;

            h2 {
                font-size: 2rem;
                font-weight: 400;
                color: $heading-color;
                margin-top: 20px;
            }

            p{
                font-size: 16px;
                font-weight: 400;
                line-height: 1.6em;
            }
        }

        .card-paragraph {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 16px;
            font-weight: 400;
            z-index: 10;
            transition: all 0.5s ease-in-out;
            line-height: 1.6em;
            text-align: left;

            &.quote::before{
                content: open-quote;
                font-size: 24px;
                position: absolute;
                left: -20px; /* Adjust this depending on your layout */
                top: -10px;
                color: $heading-color;
            }   

            &.quote::after {
                content: close-quote;
                font-size: 24px;
                position: absolute;
                right: 3px; /* Adjust this depending on your layout */
                bottom: -10px;
                color: $heading-color;
              }

        }


        .card-gradient {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(37,41,45, 0.3); // Gradient background
          opacity: 1;

        }

        .card-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom right, rgba(37,41,45, 0.5), rgba(37,41,45, 0.5), rgba(255,255,255, 0.5)); // Gradient background
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: opacity 0.5s ease-in-out; 
            color: white;
            padding: 30px;
        
            .card-subtitle {
                margin-top: 50px;
              font-size: 1.2rem;
              font-weight: normal;
              text-align: center;
            }
          }
        
          &:hover {

            .card-title {
                top: 30px; // Move to the top on hover
                transform: translateX(-50%); // Maintain horizontal centering
                color: white;
            }
            .card-overlay {
                opacity: 1;
            }
        }
    }

    .accordion-wrapper{
        .accordion {
            margin-top: 30px;
            padding: 0;

            .accordion-item:first-of-type{
                border-radius: 0;

                .accordion-button {
                    border-radius: 0;
                }
            }
        }
    }

    .wpo-service-single-title{
      h2 {
        margin-bottom: 20px;
      }
    }

    /*** Compare slides ***/
    .compare-wrapper {
        height: 500px;
        @media (max-width:575px) {
            height: 185px;
    
        }
    }

    /*** ABOUT US PAGE ***/
    .about-cta-wrapper{
        margin-top: 80px;
        margin-bottom: 80px;

        @media (max-width:455px) {
            margin-top: 0px;
            margin-bottom: 0px;
        
        }

        .lr-banner {
            flex-direction: row;
            @media (max-width:455px) {
                flex-direction: column-reverse;
                padding-bottom: 50px;

                .wpo-service-single-title {
                    margin-top: 30px;
                }
            
            }

        }
    }