/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:    Sailo | Factory & Industry HTML5 Template
Version:       1.0.0
-------------------------------------------------------------------*/


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------

1. General
	1.1 Theme Reset Style
	1.2 Global Elements

2. header
	2.1 topbar
	2.2 navigation

3. content
	3.1 wpo-hero-slider
	3.2 wpo-feature-section
	3.3 wpo-about-section
	3.4 wpo-service-section
	3.5 wpo-history-section
	3.6 wpo-team-section 
	3.7 wpo-project-section
	3.8 wpo-testimonial-section
	3.9 wpo-blog-section

4. wpo-footer

5. Home-style-2
   5.1 wpo-feature-section-s2
   5.2 wpo-about-section-s2
   5.3 wpo-service-section-s2
   5.4 wpo-pricing-section

6. Home-style-3
   6.1 wpo-about-section-s3 
   6.2 wpo-service-section-s3
   6.3 wpo-feature-section-s3
   6.4 wpo-partners-section
   6.5 wpo-pricing-page


7. wpo-service-single

8. wpo-project-single

9. wpo-team-single

10. wpo-shop-page

11. wpo-shop-single-page

12. wpo-cart-page-style

13. wpo-checkout-page-style

14. wpo-blog-pg-section

15. wpo-blog-single-section

16. wpo-contact-pg-section

17. wpo-faq-page

18. error-404-section





----------------------------------------------------------------*/


/*------------------------------------------------------------------
1. General
----------------------------------------------------------------*/

// helpers
@import "helpers/variables";
@import "helpers/functions";
@import "helpers/mixins";

// base
@import "base/base";


// components
@import "components/section-title";
@import "components/buttons";
@import "components/form";
@import "components/social-links";
@import "components/page-title";
@import "components/preloader";
@import "components/blog-sidebar";
@import "components/pagination";

// layout
@import "layout/header";
@import "layout/hero-slider";
@import "layout/footer";

// components
@import "components/sticky-header";


// page
@import "page/home-default";
@import "page/home-style-s2";
@import "page/_home-style-s4";
@import "page/service-single";
@import "page/project-single";
@import "page/team-single";
@import "page/shop";
@import "page/shop-single";
@import "page/cart";
@import "page/checkout";
@import "page/_blog";
@import "page/_blog-single";
@import "page/contact";
@import "page/faq";
@import "page/error-404";

.lr-banner {
	padding: 20px 0;
	

	.f-left {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		img {
			width: 500px;
			height: auto;
		}
	}

	.f-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		img {
			width: 500px;
			height: auto;
		}
	}

}

.white-bg{
	background-color: white !important;
}

.location-list{
	p {
		color: rgba( 255, 255, 255, 0.8) !important;
		font-size: 11px;;
	}
}

.slick-prev, .slick-next {
	z-index: 999;
}

.slick-prev {
	@media (max-width:450px) {
		left: -14px;
}
}

.slick-next {
	@media (max-width:450px) {
		right: 9px;
}
}

.slick-prev:before, .slick-next:before {
	color: #CC3A29;
	font-size: 44px;
}

.service-slider .slick-list {
	height: 420px; 
	@media (max-width: 450px) {
		height: 390px;
	}
}


.about-page {
	.list-widget{
		ul{
				list-style: none;

				li{
						padding: 7px 0;
						padding-left: 35px;
						position: relative;

						&:before{
							 position: absolute;
							 left: 0;
							 top: 50%;
							 transform: translateY(-50%);
							 width: 25px;
							 height: 25px;
							 line-height: 25px;
							 border-radius: 50%;
							 background: transparentize($theme-primary-color, .9);
							 content: "\e64c";
							 font-family: "themify";
							 text-align: center;
							 color: $theme-primary-color;
							 font-size: 14px;
						}
				}
		}
}
}

.blog-title {
	display: flex;
	align-items: center;
}